/* ubuntu-300normal - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Ubuntu Light '),
    local('Ubuntu-Light'),
    url('./files/ubuntu-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ubuntu-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* ubuntu-300italic - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src:
    local('Ubuntu Light italic'),
    local('Ubuntu-Lightitalic'),
    url('./files/ubuntu-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ubuntu-latin-300italic.woff') format('woff'); /* Modern Browsers */
}

/* ubuntu-400normal - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Ubuntu Regular '),
    local('Ubuntu-Regular'),
    url('./files/ubuntu-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ubuntu-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* ubuntu-400italic - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src:
    local('Ubuntu Regular italic'),
    local('Ubuntu-Regularitalic'),
    url('./files/ubuntu-latin-400italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ubuntu-latin-400italic.woff') format('woff'); /* Modern Browsers */
}

/* ubuntu-500normal - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Ubuntu Medium '),
    local('Ubuntu-Medium'),
    url('./files/ubuntu-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ubuntu-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* ubuntu-500italic - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src:
    local('Ubuntu Medium italic'),
    local('Ubuntu-Mediumitalic'),
    url('./files/ubuntu-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ubuntu-latin-500italic.woff') format('woff'); /* Modern Browsers */
}

/* ubuntu-700normal - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Ubuntu Bold '),
    local('Ubuntu-Bold'),
    url('./files/ubuntu-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ubuntu-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* ubuntu-700italic - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src:
    local('Ubuntu Bold italic'),
    local('Ubuntu-Bolditalic'),
    url('./files/ubuntu-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/ubuntu-latin-700italic.woff') format('woff'); /* Modern Browsers */
}

